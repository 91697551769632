import React from 'react'
import Button from '../../Button'
import InternalLink from './InternalLink'
const locale = process.env.GATSBY_LOCALE

const LinkButton = ({ node }) => (
  <InternalLink to={node.link._ref}>
    <Button kind={node.type} inverted={node.inverted}>
      {node.label[locale]}
    </Button>
  </InternalLink>
)

export default LinkButton
