import React, { useState } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { rem } from 'polished'
import { Formik, Form, Field } from 'formik'

import { contact } from '../../../api'
import { Container, Row, Column } from '../../Grid'
import TextArea from '../../TextArea'
import Button from '../../Button'

const locale = process.env.GATSBY_LOCALE
const StyledRow = styled(Row)`
  margin-top: -${rem(16)};
`

const StyledContainer = styled(Container).attrs(({ theme }) => ({
  sm: theme.breakpoints.sm,
  md: theme.breakpoints.sm,
  lg: theme.breakpoints.sm,
}))``

const StyledColumn = styled(Column)`
  text-align: left;
  input {
    border-color: ${ifProp('error', 'red')};
  }
`

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin: ${rem(32)} auto;
`

const ErrorMessage = styled.p`
  font-size: ${rem(14)};
  color: ${theme('colors.warning')};
`

const initialValues = {
  name: '',
  email: '',
  subject: '',
  phone: '',
  message: '',
}

const required = value => value === ''

const ContactUs = ({ node: labels }) => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        window.dataLayer.push({
          event: 'formSubmitted',
          label: 'Contact Us',
        })
        setError('')
        contact(values)
          .then(() => {
            setSubmitted(true)
            setSubmitting(false)
            resetForm()
          })
          .catch(e => {
            setError(labels.error[locale])
            setSubmitting(false)
          })
      }}
    >
      {({ errors, isSubmitting, isValid }) => (
        <StyledContainer as={Form}>
          <StyledRow>
            <StyledColumn sm={1 / 2} error={errors.name}>
              <label>{labels.name[locale]}</label>
              <Field name="name" type="text" validate={required} />
              {errors.name && (
                <ErrorMessage>{labels.required[locale]}</ErrorMessage>
              )}
            </StyledColumn>
            <StyledColumn sm={1 / 2} error={errors.email}>
              <label>{labels.email[locale]}</label>
              <Field name="email" type="text" validate={required} />
              {errors.email && (
                <ErrorMessage>{labels.required[locale]}</ErrorMessage>
              )}
            </StyledColumn>
            <StyledColumn>
              <label>{labels.phone[locale]}</label>
              <Field name="phone" type="text" />
            </StyledColumn>
            <StyledColumn>
              <label>{labels.subject[locale]}</label>
              <Field name="subject" type="text" />
            </StyledColumn>
            <StyledColumn>
              <label>{labels.message[locale]}</label>
              <Field name="message" component={TextArea} />
            </StyledColumn>
            <StyledColumn>
              <StyledButton
                type="submit"
                disabled={isSubmitting || !isValid || submitted}
              >
                {submitted ? labels.sent[locale] : labels.send[locale]}
              </StyledButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      )}
    </Formik>
  )
}

export default ContactUs
