import React from 'react'
import styled, { css } from 'styled-components'
import BaseBlockContent from '@sanity/block-content-to-react'
import { rem } from 'polished'
import { withProp, prop } from 'styled-tools'

import colorsByType from '../Block/colorsByType'
import serializers from './serializers'

const StyledBlockContent = styled(BaseBlockContent)`
  ${({ type }) =>
    type &&
    type !== 'transparent' &&
    css`
      padding: ${rem(16)};
      margin: ${rem(16)} 0;
    `}
  margin: ${rem(12)} 0;
  border-radius: 5px;
  text-align: ${prop('align', '')};
  ${withProp('type', colorsByType)};
`

const MyBlockContent = props => (
  <StyledBlockContent
    renderContainerOnSingleChild
    serializers={serializers}
    {...props}
  />
)

export default MyBlockContent
