import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import getYoutubeId from 'get-youtube-id'

const Wrapper = styled.div`
  margin: ${rem(32)} auto;
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: ${({ aspect }) => aspect * 100}%;
  > iframe {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    border: none;
  }
`

const Youtube = ({ node }) => {
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    setLoaded(true)
  }, [])
  return (
    <Wrapper aspect={315 / 560}>
      {loaded && (
        <iframe
          title="instruction-video"
          src={`https://www.youtube.com/embed/${getYoutubeId(node.url)}`}
          allowFullScreen
        />
      )}
    </Wrapper>
  )
}

export default Youtube
