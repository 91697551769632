import React, { useState } from 'react'
import styled from 'styled-components'
import { theme, ifProp } from 'styled-tools'
import { rem } from 'polished'
import { Formik, Form, Field } from 'formik'

import { orderBundle } from '../../../api'
import { Container, Row, Column } from '../../Grid'
import Button from '../../Button'
import FormikSelect from '../../Select/FormikSelect'

const locale = process.env.GATSBY_LOCALE

const StyledContainer = styled(Container).attrs(({ theme }) => ({
  sm: theme.breakpoints.sm,
  md: theme.breakpoints.sm,
  lg: theme.breakpoints.sm,
}))``

const StyledRow = styled(Row)`
  margin-top: -${rem(16)};
`

const StyledColumn = styled(Column)`
  text-align: left;
  input,
  .react-select__control {
    border-color: ${ifProp('error', 'red')};
  }
`

const StyledButton = styled(Button)`
  display: block;
  width: 100%;
  margin: ${rem(32)} auto;
`

const ErrorMessage = styled.p`
  font-size: ${rem(14)};
  color: ${theme('colors.warning')};
`

const Price = styled.p`
  padding: 0 ${rem(8)};
  font-weight: normal;
`

const initialValues = {
  email: '',
  memberCount: '',
  duration: null,
  chorusName: '',
  promoCode: '',
}

const required = value => value === '' || value === null

const currency = new Intl.NumberFormat(
  `${locale.slice(0, 2)}-${locale.slice(2)}`,
  {
    style: 'currency',
    currency: { svSE: 'SEK', enUS: 'USD' }[locale],
  }
)

const getPrice = (memberCount, duration) => {
  if (!memberCount || !duration) return '–'
  const price =
    parseFloat(duration.match(/\(\$?(\d+(.\d+)?)(\skr)?\s\//)?.[1]) || 0
  return currency.format(Math.min(memberCount, 50) * price)
}

const BundleForm = ({ node: labels }) => {
  const [submitted, setSubmitted] = useState(false)
  const [error, setError] = useState('')

  const subscriptionOptions = labels.subscriptionOptions.map(option => ({
    label: option[locale],
    value: option[locale],
  }))

  return (
    <Formik
      initialValues={initialValues}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const body = {
          price: getPrice(values.memberCount, values.duration?.value),
          ...values,
        }

        window.dataLayer.push({
          event: 'formSubmitted',
          label: 'Bundle Request',
        })
        setError('')
        orderBundle(body)
          .then(() => {
            setSubmitted(true)
            setSubmitting(false)
            resetForm()
          })
          .catch(e => {
            setError(labels.error[locale])
            setSubmitting(false)
          })
      }}
    >
      {({ errors, values, isSubmitting, isValid }) => (
        <StyledContainer as={Form}>
          <StyledRow>
            <StyledColumn error={errors.email}>
              <label>{labels.email[locale]}</label>
              <Field name="email" type="text" validate={required} />
              {errors.email && (
                <ErrorMessage>{labels.required[locale]}</ErrorMessage>
              )}
            </StyledColumn>
            <StyledColumn error={errors.memberCount}>
              <label>{labels.memberCount[locale]}</label>
              <Field
                name="memberCount"
                type="number"
                pattern="\d*"
                validate={required}
              />
              {errors.memberCount && (
                <ErrorMessage>{labels.required[locale]}</ErrorMessage>
              )}
            </StyledColumn>
            <StyledColumn error={errors.duration}>
              <label>{labels.duration[locale]}</label>
              <Field
                name="duration"
                component={FormikSelect}
                options={subscriptionOptions}
                validate={required}
              />
              {errors.duration && (
                <ErrorMessage>{labels.required[locale]}</ErrorMessage>
              )}
            </StyledColumn>
            <StyledColumn>
              <label>{{ svSE: 'Pris', enUS: 'Price' }[locale]}</label>
              <Price>
                {getPrice(values.memberCount, values.duration?.value)}
              </Price>
            </StyledColumn>
            <StyledColumn>
              <label>{labels.chorusName[locale]}</label>
              <Field name="chorusName" type="text" />
            </StyledColumn>
            <StyledColumn>
              <label>{labels.promoCode[locale]}</label>
              <Field name="promoCode" type="text" />
            </StyledColumn>
            <StyledColumn>
              <StyledButton
                type="submit"
                disabled={isSubmitting || !isValid || submitted}
              >
                {submitted ? labels.sent[locale] : labels.send[locale]}
              </StyledButton>
              {error && <ErrorMessage>{error}</ErrorMessage>}
            </StyledColumn>
          </StyledRow>
        </StyledContainer>
      )}
    </Formik>
  )
}

export default BundleForm
