import React from 'react'
import Select from './'

const FormikSelect = ({ field, form, disabled, options }) => (
  <Select
    value={field.value}
    options={options}
    onChange={value => form.setFieldValue(field.name, value)}
    onBlur={() => form.setFieldTouched(field.name)}
    isDisabled={disabled}
  />
)

export default FormikSelect
