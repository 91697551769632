import React, { useRef, useEffect } from 'react'
import styled from 'styled-components'

const StyledTextArea = styled.textarea`
  width: 100%;
  overflow: hidden;
  resize: none;
`

const TextArea = ({ field, form, ...props }) => {
  const ref = useRef(null)
  useEffect(() => {
    ref.current.style.height = 0
    ref.current.style.height = Math.max(150, ref.current.scrollHeight) + 'px'
  }, [field.value])

  return <StyledTextArea ref={ref} {...field} {...props} />
}

export default TextArea
