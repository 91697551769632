import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'
import { media } from '../../../theme'
import { Container, Row, Column } from '../../Grid'
import Image from './Image'

const StyledColumn = styled(Column)`
  margin-top: ${rem(48)};
`

const StyledImage = styled(Image)`
  max-width: 100%;
  margin: 0 10vw;
  ${media('sm')`
    margin: ${rem(16)} 0;
  `}
`

const FigCaption = styled.figcaption`
  font-size: ${rem(14)};
  line-height: 1.5;
  text-align: center;
  margin: ${rem(8)} ${rem(16)};
`

const ImageGroup = ({ node }) => (
  <Container as="section">
    <Row>
      {node.images.map(node => (
        <StyledColumn sm={1 / 3} md={1 / 4} key={node._key} as="figure">
          <StyledImage node={node} />
          {node.caption && <FigCaption>{node.caption}</FigCaption>}
        </StyledColumn>
      ))}
    </Row>
  </Container>
)

export default ImageGroup
