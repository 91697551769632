import React from 'react'
import styled from 'styled-components'

import { Container, Row, Column } from '../../Grid'
import BlockContent from '../index'

const FlexColumn = styled(Column)`
  display: flex;
  > * {
    flex-grow: 1;
  }
`

const MultiBlock = ({ node }) => (
  <Container>
    <Row>
      {node.blocks.map(block => (
        <FlexColumn key={block._key} sm={1 / node.blocks.length}>
          <BlockContent blocks={block.body} type={node.type} />
        </FlexColumn>
      ))}
    </Row>
  </Container>
)

export default MultiBlock
