import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import InternalLink from './InternalLink'

export const plus = styled.span`
  color: ${theme('colors.plus')};
`
export const pro = styled.span`
  color: ${theme('colors.pro')};
`

export const bundle = styled.span`
  color: ${theme('colors.primary')};
`

export const internalLink = ({ mark, children }) => (
  <InternalLink to={mark.page._ref}>{children}</InternalLink>
)

export const link = ({ mark, children }) => (
  <a href={mark.href} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
)
