import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import Social from '../../Logo/Social'
import AppStore from '../../Logo/AppStore'
import PlayStore from '../../Logo/PlayStore'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;

  > * {
    margin: ${rem(8)} ${rem(20)};
    color: inherit;
    &:first-child {
      margin-left: 0;
    }
    &:last-child {
      margin-right: 0;
    }
  }
`

const StyledSocial = styled(Social)`
  height: ${rem(40)};
`

const SocialButtonGroup = () => (
  <Wrapper>
    <a
      href="https://www.facebook.com/chorusclass/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledSocial type="Facebook" />
    </a>
    <a
      href="https://www.instagram.com/chorusclass/"
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledSocial type="Instagram" />
    </a>
    <a
      href="https://www.youtube.com/channel/UCqkh2y_2AA3xUf37jouLS-g"
      target="_blank"
      rel="noopener noreferrer"
    >
      <StyledSocial type="Youtube" />
    </a>
  </Wrapper>
)

const StyledLink = styled.a`
  max-width: ${rem(200)};
  margin: ${rem(8)};
  &:first-child {
    margin-left: 0;
  }
  &:last-child {
    margin-right: 0;
  }
`

const StoreButtonGroup = () => (
  <Wrapper>
    <StyledLink
      href="https://apps.apple.com/app/id1120676185?utm_source=chorusclass.com&utm_medium=web"
      target="_blank"
      rel="noopener noreferrer"
    >
      <AppStore />
    </StyledLink>
    <StyledLink
      href="https://play.google.com/store/apps/details?id=com.chorusclass.chorusclass&utm_source=chorusclass.com&utm_medium=web"
      target="_blank"
      rel="noopener noreferrer"
    >
      <PlayStore />
    </StyledLink>
  </Wrapper>
)

const ButtonGroup = ({ node }) =>
  React.createElement(
    { social: SocialButtonGroup, store: StoreButtonGroup }[node.value]
  )

export default ButtonGroup
