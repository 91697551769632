import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

const Iframe = styled.iframe`
  border: none;
  display: block;
  width: 100%;
  margin: ${rem(32)} auto;
  max-width: 720px;
`

const Embed = ({ node: { src, height } }) => (
  <Iframe src={src} height={height} />
)

export default Embed
