import React from 'react'

import Youtube from './Youtube'
import Image from './Image'
import ImageGroup from './ImageGroup'
import MultiBlock from './MultiBlock'
import ButtonGroup from './ButtonGroup'
import LinkButton from './LinkButton'
import ContactUs from './ContactUs'
import Subscribe from './Subscribe'
import BundleForm from './BundleForm'
import Embed from './Embed'
import FAQ from './FAQ'
import { List, ListItem } from './List'
import * as marks from './marks'
import MyBlockContent from '../'

const locale = process.env.GATSBY_LOCALE
const BlockContent = ({ node }) => <MyBlockContent blocks={node[locale]} />

const serializers = {
  types: {
    youtube: Youtube,
    buttonGroup: ButtonGroup,
    image: Image,
    wrappedImage: Image,
    wrappedImageGroup: ImageGroup,
    multiBlock: MultiBlock,
    button: LinkButton,
    contactUs: ContactUs,
    subscribe: Subscribe,
    bundleForm: BundleForm,
    faq: FAQ,
    intlContent: BlockContent,
    intlBlockContent: () => null,
    embed: Embed,
  },
  list: List,
  listItem: ListItem,
  marks,
}

export default serializers
