import styled from 'styled-components'
import { ifProp, theme } from 'styled-tools'
import { tint, rem } from 'polished'

const tintDisabled = color =>
  ifProp('disabled', props => tint(0.6, color(props)), color)

const buttonColor = tintDisabled(
  props => props.theme.colors[props.kind || 'primary']
)

export default styled.button.attrs(({ type }) => ({
  type: type || 'button',
}))`
  margin: ${rem(16)};
  background: ${ifProp('inverted', 'transparent', buttonColor)};
  border: 1px solid;
  border-color: ${ifProp('inverted', buttonColor, 'transparent')};
  color: ${ifProp('inverted', buttonColor, theme('colors.white'))};
`
