import React from 'react'

import { Row, Column } from '../Grid'
import Block from '../Block'
import BlockContent from '../BlockContent'

const locale = process.env.GATSBY_LOCALE

const Module = ({ module, children, first }) => {
  const Hx = first ? 'h1' : 'h2'
  return (
    <Block
      image={module.image && module.image.asset._ref}
      type={module.type}
      eager={first}
    >
      <Row>
        <Column>
          {module.title && module.title[locale] && (
            <Hx>
              {module.title[locale]}
              {module.extraTitle && module.extraTitle[locale] && (
                <strong>{module.extraTitle[locale]}</strong>
              )}
            </Hx>
          )}
          {children}
        </Column>
      </Row>
    </Block>
  )
}

const BlockModule = ({ module, first, even }) => {
  if (module._type === 'tutorial') {
    const [title, extraTitle] = module.title[locale].split('  ')
    const video = module.video
      ? [
          {
            _key: module.video._key,
            _type: 'youtube',
            url: module.video.url,
          },
        ]
      : []
    const content = {
      _type: 'intlContent',
      [locale]: [...video, ...module.content[locale]],
    }
    module = {
      title: { [locale]: title + ' ' },
      extraTitle: { [locale]: extraTitle },
      align: 'left',
      body: content,
      type: even ? 'background' : 'white',
    }
  }
  return (
    <Module module={module} first={first}>
      {module.body && (
        <BlockContent blocks={module.body} align={module.align} />
      )}
    </Module>
  )
}

export default BlockModule
