import React from 'react'
import styled from 'styled-components'
import { rem, em } from 'polished'

const List = styled.ul`
  list-style: none;
  padding-left: ${em(28)};
  line-height: 1.5;
  text-align: left;
  margin: ${rem(16)} 0;
  font-size: ${rem(16)};
`

const WrappedList = props => <List {...props} />

const Item = ({ node, children, className }) => (
  <li className={className}>
    <Checkmark type={node.style} />
    {children}
  </li>
)

const ListItem = styled(Item)`
  position: relative;
  margin-bottom: ${rem(8)};
`

const WrappedListItem = props => <ListItem {...props} />

const Checkmark = styled.div`
  position: absolute;
  left: -${em(28)};
  width: ${em(20)};
  height: ${em(20)};
  border-radius: 50%;
  background: transparent;

  &:before {
    content: '';
    border-left: ${em(3)} solid
      ${props => props.theme.colors[props.type] || props.theme.colors.secondary};
    border-bottom: ${em(3)} solid
      ${props => props.theme.colors[props.type] || props.theme.colors.secondary};
    display: block;
    width: 65%;
    height: 35%;
    position: absolute;
    top: 30%;
    left: 22%;
    transform: rotate(-45deg);
  }
`

export { WrappedList as List, WrappedListItem as ListItem }
