import React from 'react'
import styled from 'styled-components'
import { theme } from 'styled-tools'
import { rem } from 'polished'
import slugify from 'slugify'
import MyBlockContent from '../'

const locale = process.env.GATSBY_LOCALE

const List = styled.ul`
  list-style: none;
  padding-left: 0;
  margin: 0;
  :first-child {
    margin-bottom: ${rem(64)};
  }
`

const ListItem = styled.li`
  margin-bottom: ${rem(16)};
  a {
    color: ${theme('colors.secondary')};
  }
`

const BigListItem = styled.li`
  margin-bottom: ${rem(64)};
`

const FAQ = ({ node }) => (
  <div>
    <List>
      {node.questions.map(question => (
        <ListItem>
          <a href={`#${slugify(question.question[locale])}`}>
            {question.question[locale]}
          </a>
        </ListItem>
      ))}
    </List>
    <List>
      {node.questions.map(question => (
        <BigListItem id={slugify(question.question[locale])}>
          <h3>{question.question[locale]}</h3>
          <MyBlockContent blocks={question.answer[locale]} />
        </BigListItem>
      ))}
    </List>
  </div>
)

export default FAQ
