import React from 'react'
import styled from 'styled-components'
import { rem } from 'polished'

import ImageComponent from '../../Image'

const StyledImage = styled(ImageComponent)`
  max-width: ${rem(440)};
  margin: 0 auto;
`

const Image = ({ node, className }) =>
  node.image ? (
    <StyledImage
      className={className}
      image={node.image.asset._ref}
      title={node.title}
      alt={node.alt}
      loading={node.eager ? 'eager' : 'lazy'}
    />
  ) : (
    <StyledImage image={node.asset._ref} className={className} />
  )

export default Image
