import styled from 'styled-components'
import Select from 'react-select'
import { theme, withProp } from 'styled-tools'
import { rem, tint } from 'polished'

const height = 42
export default styled(Select).attrs({
  classNamePrefix: 'react-select',
})`
  .react-select__ {
    &control {
      min-height: ${rem(height)};
      font-weight: 500;
      border-radius: 1px;
    }
    &input {
      input:focus {
        box-shadow: none;
      }
    }
    &indicator-separator {
      display: none;
    }
    &clear-indicator,
    &dropdown-indicator {
      display: flex;
      align-items: center;
      padding: 0 ${rem(4)};
      width: ${rem(32)};
      height: ${rem(32)};
    }
    &value-container {
      padding: 0 ${rem(8)};
      &--is-multi {
        padding: 0 ${rem(4)};
      }
    }
    &single-value {
      color: ${theme('colors.primaryText')};
      padding: ${rem(1)} 0;
      transition: opacity 300ms;
      &--is-disabled {
        opacity: 0.5;
      }
    }
    &multi-value {
      padding: ${rem(3)};
      background: ${theme('colors.primary')};
      color: ${theme('colors.white')};
      align-items: center;
      &__label {
        background: transparent;
        color: inherit;
        font-size: 100%;
        padding: ${rem(3)};
      }
      &__remove {
        cursor: pointer;
        width: ${rem(16)};
        height: ${rem(16)};
        padding: 0;
        margin-top: ${rem(1)};
        &:hover {
          background: transparent;
          color: inherit;
        }
      }
    }
    &menu {
      font-weight: 500;
      border-radius: 1px;
      max-height: ${rem(300)};
      overflow-y: auto;
    }
    &menu-list {
      padding: 0;
      border: none;
      max-height: ${rem(300)};
    }
    &option {
      background: ${theme('colors.white')};
      color: ${theme('colors.primaryText')};
      border-bottom: 1px solid ${theme('colors.lightGray')};
      height: ${rem(height)};
      line-height: ${rem(height)};
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 0 ${rem(18)};
      &--is-selected {
        background: ${withProp('theme.colors.primary', tint(0.75))};
      }
      &--is-focused {
        background: ${theme('colors.primary')};
        color: ${theme('colors.white')};
      }
    }
  }
`
