const PREFIX = '/.netlify/functions'
const post = (path, data) =>
  fetch(`${PREFIX}/${path}`, {
    method: 'POST',
    mode: 'same-origin',
    cache: 'no-cache',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  }).then(response => {
    if (response.status !== 200) {
      return response.json().then(message => {
        throw new Error(message)
      })
    }
    return response.json()
  })

export const orderBundle = data => post('orderBundle', data)
export const subscribe = data => post('subscribe', data)
export const contact = data => post('contact', data)
